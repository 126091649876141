define('fleetbot/helpers/is-record-of-type', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.isRecordOfType = isRecordOfType;
  function isRecordOfType(params /*, hash*/) {
    const [record, modelName] = params;
    return record.get('constructor.modelName') == modelName;
  }

  exports.default = Ember.Helper.helper(isRecordOfType);
});
