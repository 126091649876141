define('fleetbot/controllers/organisations/organisation/create-cruise', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  Array.prototype.diff = function (a) {
    return this.filter(function (i) {
      return a.indexOf(i) < 0;
    });
  };

  exports.default = Ember.Controller.extend({

    selected: Ember.computed.filterBy('model.uavs', 'cruisePickerChecked', true),

    allSelected: function () {
      return this.get('model.uavs').every(u => u.get('cruisePickerChecked'));
    }.property('model.uavs.@each.cruisePickerChecked'),

    someSelected: function () {
      return this.get('model.uavs').any(u => u.get('cruisePickerChecked')) && this.get('model.uavs').any(u => !u.get('cruisePickerChecked'));
    }.property('model.uavs.@each.cruisePickerChecked'),

    actions: {

      presentUAVPickerDialog() {
        this.set("showUAVPickerDialog", true);
      },

      closeUAVPickerDialog(reason) {
        this.set("showUAVPickerDialog", false);
      },

      headerCheckedStateChanged(state) {
        this.get('model.uavs').forEach(u => u.set('cruisePickerChecked', state));
      },

      async save() {

        this.set('saveError', "");

        const store = this.get('store');
        const me = await store.find('user', this.get('session.currentUser.uid'));
        const organisation = this.get('model.organisation');

        const newCruise = store.createRecord('cruise', {
          title: this.get('title'),
          uavs: this.get('selected'),
          organisation,
          createUser: me,
          createTimestamp: Date.now()
        });

        try {
          await newCruise.save();
          this.transitionToRoute('cruises.cruise', newCruise);
        } catch (error) {
          this.set('saveError', error);
          return;
        }
      }
    }
  });
});
