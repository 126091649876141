define('fleetbot/routes/organisations/organisation', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    model(params) {
      return this.get('store').find('organisation', params.organisation_id);
    },

    async setupController(controller, model) {
      this._super(controller, model);

      const userId = this.get('session.currentUser.uid');
      const organisationId = this.paramsFor('organisations.organisation').organisation_id;

      let permission, permissionRequest;
      const store = this.get('store');

      async function tryFind(model, id) {
        try {
          return await store.findRecord(model, id);
        } catch (err) {
          if (err.message.includes('no record was found')) {
            return null;
          }
          throw err;
        }
      }

      controller.setProperties({
        organisation_id: this.paramsFor('organisations.organisation').organisation_id,
        permission: await tryFind('user-organisation', `${userId}~${organisationId}`),
        permissionRequest: await tryFind('user-organisation-request', `${userId}~${organisationId}`)
      });
    }
  });
});
