define('fleetbot/models/mission', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    goal: _emberData.default.attr('string'),
    uav: _emberData.default.belongsTo('uav', { inverse: null }),
    autopilot: _emberData.default.belongsTo('autopilot', { inverse: null }),
    inProgress: _emberData.default.attr('boolean'),
    cruise: _emberData.default.belongsTo('cruise', { inverse: null }),
    createTimestamp: _emberData.default.attr('number'),
    createUser: _emberData.default.belongsTo('user', { inverse: null })
  });
});
