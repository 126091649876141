define('fleetbot/routes/missions/mission/log', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    model() {
      const params = this.paramsFor('missions.mission');
      return Ember.RSVP.hash({
        me: this.get('store').find('user', this.get('session.currentUser.uid')),
        mission: this.modelFor('missions.mission'),
        logEntries: this.get('store').query('mission-log-entry', {
          orderBy: 'mission',
          equalTo: params.mission_id
        })
      });
    }
  });
});
