define('fleetbot/components/file-upload', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    actions: {
      upload(event) {
        const files = event.target.files;
        this.get('onFiles')(files);
      },
      add() {
        this.$("input[type='file']").click();
      }
    }
  });
});
