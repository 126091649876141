define('fleetbot/adapters/user-organisation-request', ['exports', 'emberfire/adapters/firebase'], function (exports, _firebase) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _firebase.default.extend({
    generateIdForRecord(store, type, inputProperties) {
      if (!inputProperties.user || !inputProperties.organisation) {
        throw new Error("User and Organisation properties are required to generate an id.");
      }
      return `${inputProperties.user.get('id')}~${inputProperties.organisation.get('id')}`;
    }
  });
});
