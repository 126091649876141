define('fleetbot/controllers/organisations/organisation/users', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({

    actions: {

      async searchUsers(query) {

        const existingUsers = await Ember.RSVP.all(this.get('model').map(p => p.get('user')));

        const store = this.get('store');
        const response = await fetch(`https://us-central1-fleetbot-ai.cloudfunctions.net/search?q=${query}&t=user`);
        const data = await response.json();
        const userResults = await Ember.RSVP.all(data.map(r => store.find('user', r.ref)));

        //hide existing users
        const filteredUsers = userResults.filter(u => !existingUsers.includes(u));

        return filteredUsers;
      },

      async addUser(user) {

        if (!user) {
          throw new Error("User is required");
        }

        const store = this.get('store');
        const organisation = await store.find('organisation', this.get('organisation_id'));
        const me = await store.find('user', this.get('session.currentUser.uid'));

        const newRecord = store.createRecord('user-organisation', {
          organisation,
          user,
          permission: 'editor',
          createUser: me,
          createTimestamp: Date.now()
        });

        await newRecord.save();

        this.set('userSearchText', null);
      },

      async removePermission(permission) {
        await permission.destroyRecord();
      },

      async authorisePermissionRequest(request) {
        try {
          const store = this.get('store');
          const me = await store.find('user', this.get('session.currentUser.uid'));

          const newRecord = store.createRecord('user-organisation', {
            organisation: request.get('organisation'),
            user: request.get('user'),
            permission: request.get('permission'),
            createUser: me,
            createTimestamp: Date.now()
          });

          await newRecord.save();
          await request.destroyRecord();
          this.set('permissionRequestError', null);
        } catch (err) {
          this.set('permissionRequestError', err);
        }
      },

      async denyPermissionRequest(request) {
        try {
          await request.destroyRecord();
          this.set('permissionRequestError', null);
        } catch (err) {
          this.set('permissionRequestError', err);
        }
      }
    }
  });
});
