define('fleetbot/models/cruise', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    title: _emberData.default.attr('string'),
    summary: _emberData.default.attr('string'),
    uavs: _emberData.default.hasMany('uav', { inverse: null }),
    organisation: _emberData.default.belongsTo('organisation', { inverse: null }),
    createTimestamp: _emberData.default.attr('number'),
    createUser: _emberData.default.belongsTo('user', { inverse: null })
  });
});
