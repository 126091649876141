define('fleetbot/routes/organisations/organisation/create-cruise', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    model() {
      const organisation_id = this.paramsFor('organisations.organisation').organisation_id;
      return Ember.RSVP.hash({
        organisation: this.modelFor('organisations.organisation'),
        uavs: this.get('store').query('uav', {
          orderBy: 'organisation',
          equalTo: organisation_id
        })
      });
    }
  });
});
