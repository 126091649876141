define('fleetbot/models/autopilot-job', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    autopilot: _emberData.default.belongsTo('autopilot', { inverse: null }),
    mission: _emberData.default.belongsTo('mission', { inverse: null }),
    originalFilename: _emberData.default.attr('string'),
    originalStoragePath: _emberData.default.attr('string'),
    outputUrl: _emberData.default.attr('string'),
    status: _emberData.default.attr('string'), //waiting,running,complete,error
    error: _emberData.default.attr('string'),
    createTimestamp: _emberData.default.attr('number'),
    createUser: _emberData.default.belongsTo('user', { inverse: null })
  });
});
