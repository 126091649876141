define('fleetbot/routes/organisations/organisation/index', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    afterModel() {
      this.transitionTo('organisations.organisation.cruises');
    }
  });
});
