define('fleetbot/router', ['exports', 'fleetbot/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  const Router = Ember.Router.extend({
    location: _environment.default.locationType,
    rootURL: _environment.default.rootURL
  });

  Router.map(function () {

    this.route('cruises', function () {
      this.route('cruise', { path: ':cruise_id' }, function () {
        this.route('index');
      });
    });

    this.route('login');

    this.route('missions', function () {
      this.route('mission', { path: ':mission_id' }, function () {
        this.route('log');
      });
    });

    this.route('not-found', { path: '/*path' });

    this.route('organisations', function () {
      this.route('organisation', { path: ':organisation_id' }, function () {
        this.route('create-cruise');
        this.route('cruises');
        this.route('uavs');
        this.route('users');
      });
    });

    this.route('users', function () {
      this.route('user', { path: ':user_id' }, function () {
        this.route('profile');
        this.route('edit');
      });
    });
  });

  exports.default = Router;
});
