define('fleetbot/components/mission-log-entry', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  function downloadURI(uri, name) {
    var link = document.createElement("a");
    link.download = name;
    link.href = uri;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  exports.default = Ember.Component.extend({
    firebaseApp: Ember.inject.service(),

    tagName: 'mission-log-entry',
    classNames: ['md-padding'],
    classNameBindings: ['deleted', 'mdWhiteframeZ1'],

    deleted: Ember.computed.alias('model.deleted'),
    mdWhiteframeZ1: Ember.computed('model.deleted', 'model.systemGenerated', function () {
      return !this.get('model.deleted') && !this.get('model.systemGenerated');
    }),

    session: Ember.inject.service(),

    actions: {
      async delete() {
        this.get('model').set('deleted', true);
        await this.get('model').save();
      },
      beginEdit() {
        this.set('editing', true);
        this.get('beginEdit')();
      },
      async endEdit(reason) {
        this.set('editing', false);
        if (reason === "ok") {
          await this.get('model').save();
        }
        this.get('endEdit')();
      },
      async download(attachment) {
        const fileRef = this.get('firebaseApp').storage().ref(attachment.get('storagePath'));
        const url = await fileRef.getDownloadURL();
        downloadURI(url, attachment.get('originalFilename'));
      },
      downloadAutopilot() {
        downloadURI(this.get('model.autopilotJob.outputUrl'), 'Results.zip');
      }
    }
  });
});
