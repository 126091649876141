define('fleetbot/components/paper-data-table-edit-dialog', ['exports', 'paper-data-table/components/paper-data-table-edit-dialog'], function (exports, _paperDataTableEditDialog) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _paperDataTableEditDialog.default;
    }
  });
});
