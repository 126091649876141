define('fleetbot/routes/users/user/edit', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    setupController(controller, model) {
      this._super(controller, model);
      this.controllerFor('profile.edit').setProperties({
        role: model.get('role'),
        bio: model.get('bio')
      });
    }
  });
});
