define('fleetbot/controllers/missions/mission/log', ['exports', 'ember-uuid'], function (exports, _emberUuid) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({

    firebaseApp: Ember.inject.service(),

    filesToAttach: Ember.A([]),

    actions: {

      beginEdit(entry) {
        this.set('editingEntry', entry);
      },

      endEdit() {
        this.set('editingEntry', null);
      },

      async endMission() {

        const store = this.get('store');
        const me = await store.find('user', this.get('session.currentUser.uid'));
        const mission = this.get('model.mission');

        const newLogEntry = store.createRecord('mission-log-entry', {
          mission,
          text: 'Ended the mission',
          systemGenerated: true,
          createUser: me,
          createTimestamp: Date.now()
        });

        await newLogEntry.save();

        mission.setProperties({
          inProgress: false
        });
        await mission.save();

        this.transitionToRoute('cruises.cruise', mission.get('cruise'));
      },

      async sendAndEndMission() {

        if (this.get('newMessage') || this.get('filesToAttach').length > 0) {
          await this.send('sendNewMessage');
        }

        await this.send('endMission');
      },

      async sendNewMessage() {
        const store = this.get('store');
        const me = await store.find('user', this.get('session.currentUser.uid'));
        const mission = this.get('model.mission');
        const text = this.get('newMessage');

        const newLogEntry = store.createRecord('mission-log-entry', {
          mission,
          text,
          systemGenerated: false,
          createUser: me,
          createTimestamp: Date.now()
        });

        if (this.get('filesToAttach')) {
          const fileRef = this.get('firebaseApp').storage().ref().child(mission.get('id'));
          const files = this.get('filesToAttach');
          const uploadedFiles = await Ember.RSVP.all(files.map(f => fileRef.child((0, _emberUuid.v4)()).put(f)));

          uploadedFiles.map((f, i) => newLogEntry.get('attachments').createRecord({
            type: files[i].type,
            originalFilename: files[i].name,
            storagePath: f.metadata.fullPath
          }));
        }

        await newLogEntry.save();

        this.setProperties({
          newMessage: null,
          filesToAttach: Ember.A([])
        });
      },

      addFiles(fileList) {
        this.get('filesToAttach').pushObjects([...fileList]);
      },

      removeAttachment(attachment) {
        this.get('filesToAttach').removeObject(attachment);
      },

      async addAutopilotFiles(fileList) {

        const store = this.get('store');
        const me = await store.find('user', this.get('session.currentUser.uid'));
        const mission = this.get('model.mission');
        const autopilot = mission.get('autopilot');
        const fileRef = this.get('firebaseApp').storage().ref().child(mission.get('id'));
        const files = [...fileList];
        const uuid = (0, _emberUuid.v4)();

        await Ember.RSVP.all(files.map(f => {

          const filename = uuid;
          const path = `${mission.get('id')}/${filename}`;

          return fileRef.child(filename).put(f).then(s => store.createRecord('autopilot-job', {
            autopilot,
            mission,
            originalFilename: f.name,
            originalStoragePath: path,
            status: 'waiting',
            createUser: me,
            createTimestamp: Date.now()
          }).save()).then(j => store.createRecord('mission-log-entry', {
            mission,
            systemGenerated: true,
            text: 'submitted a UAV result file',
            autopilotJob: j,
            createUser: me,
            createTimestamp: Date.now()
          }).save());
        }));
      }
    }
  });
});
