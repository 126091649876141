define('fleetbot/models/user-organisation', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    organisation: _emberData.default.belongsTo('organisation', { inverse: null }),
    user: _emberData.default.belongsTo('user', { inverse: null }),
    permission: _emberData.default.attr('string'),
    createTimestamp: _emberData.default.attr('number'),
    createUser: _emberData.default.belongsTo('user', { inverse: null })
  });
});
