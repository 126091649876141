define('fleetbot/controllers/users/user/edit', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    actions: {
      save() {
        const model = this.get('model');
        model.setProperties({
          role: this.get('role'),
          bio: this.get('bio')
        });
        model.save();
        this.transitionToRoute('application');
      }
    }
  });
});
