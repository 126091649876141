define('fleetbot/routes/login', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    afterLoginTransition: Ember.on('init', Ember.observer('session.isAuthenticated', function () {
      if (this.get('session.isAuthenticated')) {
        this.transitionTo('users');
      }
    }))
  });
});
