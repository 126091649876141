define('fleetbot/controllers/login', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    actions: {

      signIn: function (provider) {

        const store = this.get('store'),
              controller = this;

        function findOrCreate(id) {
          return new Ember.RSVP.Promise(resolve => store.findRecord('user', id).then(r => resolve(r), () => resolve(store.createRecord('user', { id: id }))));
        }

        return this.get('session').open('firebase', { provider: provider }).then(data => {

          return findOrCreate(data.uid).then(record => {
            record.setProperties({
              displayName: data.currentUser.displayName,
              email: data.currentUser.email,
              emailVerified: data.currentUser.emailVerified,
              photoURL: data.currentUser.photoURL,
              timestamp: Date.now()
            });
            return record.save();
          });
        }).then(() => {
          controller.transitionToRoute('users');
        }).catch(error => {
          controller.set('error', 'Could not sign you in: ' + error.message);
        });
      }
    }
  });
});
