define("fleetbot/controllers/missions/mission", ["exports", "ember-uuid"], function (exports, _emberUuid) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({

    firebaseApp: Ember.inject.service(),

    queue: "files",

    autopilots: function () {
      return this.get('store').findAll('autopilot');
    }.property(),

    actions: {

      submitResultsFile() {},
      presentAutopilotDialog() {
        this.setProperties({
          showAutopilotDialog: true,
          autopilot: this.get('model.autopilot')
        });
      },

      async closeAutopilotDialog(reason) {

        this.set('changeAutopilotError', "");

        if (reason === "ok") {

          const mission = this.get('model');
          mission.set('autopilot', this.get('autopilot'));

          try {
            await mission.save();
            this.set('showAutopilotDialog', false);
          } catch (error) {
            this.set('changeAutopilotError', error);
            return;
          }
        } else {
          this.set('showAutopilotDialog', false);
        }
      }
    }
  });
});
