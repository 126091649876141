define('fleetbot/controllers/organisations/organisation', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({

    router: Ember.inject.service(),

    selectedTab: function () {
      const currentRouteName = this.get('router.currentRouteName');
      const matchingTab = this.get('tabsDataSource').find(r => currentRouteName.startsWith(r.route));
      return matchingTab ? matchingTab.route : null;
    }.property('router.currentRouteName'),

    tabsDataSource: [{
      route: "organisations.organisation.cruises",
      name: "Cruises"
    }, {
      route: "organisations.organisation.uavs",
      name: "UAVs"
    }, {
      route: "organisations.organisation.users",
      name: "Users"
    }],

    actions: {

      async requestAccess() {
        try {
          if (this.get('permission')) {
            throw 'Permission already exists';
          }
          const organisation = this.get('model');
          const user = await this.get('store').find('user', this.get('session.currentUser.uid'));

          const permissionRequest = await this.get('store').createRecord('user-organisation-request', {
            organisation,
            user,
            permission: 'editor',
            createUser: user,
            createTimestamp: Date.now()
          });

          await permissionRequest.save();

          this.set('permissionRequest', permissionRequest);
        } catch (err) {
          this.set('requestError', err);
        }
      }

    }
  });
});
