define('fleetbot/controllers/organisations/organisation/uavs', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({

    selected: Ember.computed.filterBy('model.uavs', 'checked', true),

    allSelected: function () {
      return this.get('model.uavs').every(u => u.get('checked'));
    }.property('model.uavs.@each.checked'),

    someSelected: function () {
      return this.get('model.uavs').any(u => u.get('checked')) && this.get('model.uavs').any(u => !u.get('checked'));
    }.property('model.uavs.@each.checked'),

    actions: {

      presentAddDialog() {
        this.setProperties({
          showAddDialog: true,
          manufacturer: null,
          manufacturerModel: null,
          serialNumber: null,
          fleet: "Default",
          comment: null
        });
      },
      async closeAddDialog(reason) {

        this.set('addError', "");

        if (reason === "ok") {

          const store = this.get('store');
          const me = await store.find('user', this.get('session.currentUser.uid'));
          const organisation = this.get('model.organisation');

          const newUav = store.createRecord('uav', {
            manufacturer: this.get('manufacturer'),
            manufacturerModel: this.get('manufacturerModel'),
            serialNumber: this.get('serialNumber'),
            fleet: this.get('fleet'),
            comment: this.get('comment'),
            organisation,
            createUser: me,
            createTimestamp: Date.now()
          });

          try {
            await newUav.save();
          } catch (error) {
            this.set('addError', error);
            return;
          }
        }

        this.set('showAddDialog', false);
      },

      headerCheckedStateChanged(state) {
        this.get('model.uavs').forEach(u => u.set('checked', state));
      },

      presentConfirmDeleteDialog(vehicle) {
        this.setProperties({
          showConfirmDeleteDialog: true
        });
      },

      async closeConfirmDeleteDialog(reason) {
        if (reason === "ok") {
          await Ember.RSVP.all(this.get('selected').map(s => s.destroyRecord()));
        }
        this.set('showConfirmDeleteDialog', false);
      },

      presentEditDialog(items) {

        function distinctOrDefault(property) {
          if (items.length === 1) {
            return items[0].get(property);
          } else {
            const uniq = items.map(i => i.get(property)).uniq();
            if (uniq.length === 1) {
              return uniq[0];
            }
          }
          return "<multiple values>";
        }

        this.setProperties({
          showEditDialog: true,
          manufacturer: distinctOrDefault("manufacturer"),
          manufacturerModel: distinctOrDefault("manufacturerModel"),
          serialNumber: distinctOrDefault("serialNumber"),
          fleet: distinctOrDefault("fleet"),
          comment: distinctOrDefault("comment"),
          editing: items
        });
      },
      async closeEditDialog(reason) {

        this.set('editError', "");

        if (reason === "ok") {

          const store = this.get('store');
          const me = await store.find('user', this.get('session.currentUser.uid'));
          const items = this.get('editing');
          const properties = ['manufacturer', 'manufacturerModel', 'serialNumber', 'fleet', 'comment'];
          const controller = this;

          try {
            await Ember.RSVP.all(items.map(i => {
              const changedProperties = properties.filter(p => !(controller.get(p) === "<multiple values>" || i.get(p) === controller.get(p)));

              if (changedProperties.length > 0) {
                changedProperties.forEach(p => i.set(p, controller.get(p)));
                i.setProperties({
                  modifyUser: me,
                  modifyTimestamp: Date.now()
                });
                return i.save();
              }
            }));
          } catch (error) {
            this.set('editError', error);
            return;
          }
        }

        this.setProperties({
          showEditDialog: false,
          editing: null
        });
      }
    }
  });
});
