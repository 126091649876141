define('fleetbot/routes/organisations/organisation/cruises', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    model() {
      const organisation_id = this.paramsFor('organisations.organisation').organisation_id;
      return this.get('store').query('cruise', {
        orderBy: 'organisation',
        equalTo: organisation_id
      });
    }
  });
});
