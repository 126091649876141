define('fleetbot/routes/users/user/profile', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    model() {
      const user_id = this.paramsFor('users.user').user_id;
      return Ember.RSVP.hash({
        user: this.modelFor('users.user'),
        organisations: this.get('store').query('user-organisation', {
          orderBy: 'user',
          equalTo: user_id
        }),
        organisationsPending: this.get('store').query('user-organisation-request', {
          orderBy: 'user',
          equalTo: user_id
        })
      });
    }
  });
});
