define('fleetbot/routes/cruises/cruise/index', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    model() {
      const cruise_id = this.paramsFor('cruises.cruise').cruise_id;
      return Ember.RSVP.hash({
        cruise: this.modelFor('cruises.cruise'),
        missions: this.get('store').query('mission', {
          orderBy: 'cruise',
          equalTo: cruise_id
        })
      });
    }
  });
});
