define('fleetbot/routes/users/index', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    beforeModel() {
      this.transitionTo('users.user', this.get('session.currentUser.uid'));
    }
  });
});
