define('fleetbot/initializers/app-version', ['exports', 'ember-cli-app-version/initializer-factory', 'fleetbot/config/environment'], function (exports, _initializerFactory, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  const {
    APP: {
      name,
      version
    }
  } = _environment.default;

  exports.default = {
    name: 'App Version',
    initialize: (0, _initializerFactory.default)(name, version)
  };
});
