define('fleetbot/controllers/application', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({

    actions: {

      async search(query) {
        const store = this.get('store');
        const response = await fetch(`https://us-central1-fleetbot-ai.cloudfunctions.net/search?q=${query}`);
        const data = await response.json();
        return await Ember.RSVP.all(data.map(r => store.find(r.type, r.ref)));
      },

      searchSelection(obj) {

        this.set('searchText', null);
        const type = obj.get('constructor.modelName');
        if (type == 'user') {
          this.transitionToRoute('users.user', obj);
        } else if (type == 'organisation') {
          this.transitionToRoute('organisations.organisation', obj);
        }

        if (!this.get('searchHistory')) {
          this.set('searchHistory', Ember.A([]));
        }
        if (this.get('searchHistory').includes(obj)) {
          this.get('searchHistory').removeObject(obj);
        }
        this.get('searchHistory').insertAt(0, obj);
      },

      signOut: function () {
        const controller = this;
        this.get('session').close().then(() => controller.transitionToRoute('index'));
      },
      transitionTo(route) {
        this.transitionToRoute(route);
      }
    }
  });
});
