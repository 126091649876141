define('fleetbot/models/notification', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    user: _emberData.default.belongsTo('user', { inverse: null }),
    fromUser: _emberData.default.belongsTo('user', { inverse: null }),
    class: _emberData.default.attr('string'),
    text: _emberData.default.attr('string'),
    entityType: _emberData.default.attr('string'),
    entityId: _emberData.default.attr('string'),
    createTimestamp: _emberData.default.attr('number'),
    readTimestamp: _emberData.default.attr('number')
  });
});
