define('fleetbot/controllers/cruises/cruise/index', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({

    inProgressMissions: Ember.computed.filterBy('model.missions', 'inProgress', true),
    archivedMissions: Ember.computed.filterBy('model.missions', 'inProgress', false),

    uavsAndInProgressMission: function () {
      return this.get('model.cruise.uavs').map(uav => {
        return {
          uav,
          mission: this.get('inProgressMissions').find(m => m.get('uav.id') === uav.get('id')) // direct comparison of uavs fails
        };
      });
    }.property('model.cruise.uavs.[]', 'inProgressMissions.[]'),

    actions: {

      defaultUavAction(uav, mission) {
        if (mission) {
          this.transitionToRoute('missions.mission', mission);
        } else {
          this.setProperties({
            showStartMissionDialog: true,
            startMissionUav: uav,
            goal: null
          });
        }
      },

      async closeStartMissionDialog(reason) {

        this.set('startError', "");

        if (reason === "ok") {

          const store = this.get('store');
          const me = await store.find('user', this.get('session.currentUser.uid'));
          const cruise = this.get('model.cruise');

          const newMission = store.createRecord('mission', {
            goal: this.get('goal'),
            uav: this.get('startMissionUav'),
            inProgress: true,
            cruise,
            createUser: me,
            createTimestamp: Date.now()
          });

          const firstEntry = store.createRecord('mission-log-entry', {
            mission: newMission,
            text: 'created the mission',
            systemGenerated: true,
            createUser: me,
            createTimestamp: Date.now()
          });

          try {
            await newMission.save();
            await firstEntry.save();
            this.transitionToRoute('missions.mission', newMission);
          } catch (error) {
            this.set('startError', error);
            return;
          }
        }

        this.set('showStartMissionDialog', false);
      },

      transitionToMission(mission) {
        this.transitionToRoute('missions.mission', mission);
      }

    }

  });
});
