define('fleetbot/routes/organisations/organisation/users', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    model() {
      const organisationId = this.paramsFor('organisations.organisation').organisation_id;

      return Ember.RSVP.hash({

        myPermission: this.get('store').findRecord('user-organisation', `${this.get('session.currentUser.uid')}~${organisationId}`),

        permissions: this.get('store').query('user-organisation', {
          orderBy: 'organisation',
          equalTo: organisationId
        }),

        permissionRequests: this.get('store').query('user-organisation-request', {
          orderBy: 'organisation',
          equalTo: organisationId
        })
      });
    },

    setupController(controller, model) {
      this._super(controller, model);
      controller.setProperties({
        organisation_id: this.paramsFor('organisations.organisation').organisation_id
      });
    }
  });
});
