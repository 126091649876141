define('fleetbot/routes/index', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    beforeModel() {
      if (this.get('session.isAuthenticated')) {
        this.transitionTo('users');
      } else {
        this.transitionTo('login');
      }
    }
  });
});
