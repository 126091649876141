define('fleetbot/models/mission-log-entry', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    mission: _emberData.default.belongsTo('mission', { inverse: null }),
    autopilotJob: _emberData.default.belongsTo('autopilot-job', { inverse: null }),
    text: _emberData.default.attr('string'),
    attachments: _emberData.default.hasMany('mission-log-attachment', { async: false }),
    systemGenerated: _emberData.default.attr('boolean'),
    createTimestamp: _emberData.default.attr('number'),
    createUser: _emberData.default.belongsTo('user', { inverse: null }),
    deleted: _emberData.default.attr('boolean')
  });
});
