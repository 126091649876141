define('fleetbot/controllers/users/user/profile', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    actions: {
      presentEditDialog() {
        this.setProperties({
          showEditDialog: true,
          role: this.get('model.user.role'),
          bio: this.get('model.user.bio')
        });
      },

      async closeEditDialog(reason) {
        if (reason === "ok") {
          this.get('model.user').setProperties({
            role: this.get('role'),
            bio: this.get('bio')
          });
          await this.get('model.user').save();
        }
        this.set('showEditDialog', false);
      },

      presentAddDialog() {
        this.setProperties({
          showAddDialog: true,
          name: null,
          description: null,
          addError: null
        });
      },
      async closeAddDialog(reason) {

        this.set('addError', "");

        if (reason === "ok") {

          const store = this.get('store');
          const me = this.get('model.user');

          const newOrganisation = store.createRecord('organisation', {
            name: this.get('name'),
            description: this.get('description'),
            private: this.get('private'),
            createUser: me,
            createTimestamp: Date.now()
          });

          try {

            await newOrganisation.save();

            const defaultSecurity = store.createRecord('user-organisation', {
              user: me,
              organisation: newOrganisation,
              permission: 'owner',
              createUser: me,
              createTimestamp: Date.now()
            });

            await defaultSecurity.save();

            this.transitionToRoute('organisations.organisation', newOrganisation);
          } catch (error) {
            this.set('addError', error);
            return;
          }
        }

        this.set('showAddDialog', false);
      },

      async cancelPending(permission) {
        await permission.destroyRecord();
      }
    }
  });
});
