define('fleetbot/components/error-panel', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    errorText: function () {
      const error = this.get('error');
      if (error.errors != undefined) {
        return error.message + " " + error.errors.map(e => e.message).join(" ");
      }
      return error.toString();
    }.property('error')
  });
});
