define('fleetbot/components/paper-data-table-pagination', ['exports', 'paper-data-table/components/paper-data-table-pagination'], function (exports, _paperDataTablePagination) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _paperDataTablePagination.default;
    }
  });
});
